import React from 'react';
import styled from 'styled-components';

import Layout from '../../components/Layout.jsx';
import SEO from '../../components/Seo.jsx';
import Hero from '../../components/Hero.jsx';
import IllustratedText from '../../components/IllustratedText.jsx';
import Card, { Cards } from '../../components/Card.jsx';
import { InfoBar } from '../../components/InfoSection.jsx';

import Developper from '../../icons/Developper.jsx';

import Email from '../../icons/Email.jsx';
import Puzzle from '../../icons/Puzzle.jsx';
import Web from '../../icons/Web.jsx';
import Python from '../../icons/Python.jsx';
import ReactLogo from '../../icons/React.jsx';
import Fullstack from '../../icons/Fullstack.jsx';
import Balance from '../../icons/Balance.jsx';
import LogoHoraireSource from '../../icons/Logo.jsx';
import LogoPosologiesSource from '../../icons/LogoPosologies.jsx';
import LogoReplicadSource from '../../images/replicad.png';

import color from '../../theme';

const Section = styled.div`
  padding: 50px 30px;

  &:nth-child(odd) {
    background-color: #f8f8f8;
  }
`;

const SectionTitle = styled.h2`
  text-align: center;
  margin-top: -15px;
  font-weight: 400;
  opacity: 0.7;
`;

const LogoHoraire = () => (
  <div
    css={`
      display: flex;
      justify-content: center;
      align-items: center;
      fill: white;
      background-color: rgb(39, 87, 107);
      height: 100%;
      width: 100%;
      padding: 8%;
      border-radius: 10%;
    `}
  >
    <a href="https://horair.es/en">
      <LogoHoraireSource width="100%" />
    </a>
  </div>
);

const LogoPosologies = () => (
  <div
    css={`
      display: flex;
      justify-content: center;
      align-items: center;
      fill: #ae2727;
      background-color: #f9ffd7;
      height: 100%;
      width: 100%;
      padding: 8%;
      border-radius: 10%;
      border: 1px solid #cecece;
    `}
  >
    <a href="https://posologies.com">
      <LogoPosologiesSource size="100%" />
    </a>
  </div>
);

const LogoReplicad = () => (
  <a
    href="https://replicad.xyz"
    css={`
      display: flex;
      justify-content: center;
      align-items: center;
      fill: #ae2727;
      background-color: white;
      height: 100%;
      width: 100%;
      padding: 8%;
      border-radius: 10%;
      border: 1px solid #cecece;
      & img {
        max-width: 100%;
        object-fit: cover;
      }
    `}
  >
    <img src={LogoReplicadSource} size="100%" />
  </a>
);

const Name = styled.a`
  font-size: 0.6em;
  margin-top: 0.3em;
  color: inherit;
  text-decoration: none;
`;

const EndLink = styled.a`
  fill: white;
  stroke: ${color};
  height: 1.4em;
  display: flex;
  align-items: center;

  & > :not(:first-child) {
    margin-left: 0.5em;
  }
`;

const IndexPage = () => (
  <Layout main lang="en">
    <SEO title="Steve Genoud | QuaroTech" lang="en" description="Fullstack independent developer" />
    <Hero lang="en">
      <div>Fullstack independent developer</div>
      <Name href="mailto:steve@quaro.tech">Steve Genoud</Name>
    </Hero>
    <Section>
      <IllustratedText Illustration={Developper}>
        <ul>
          <li>You need to complete your team to achieve a goal?</li>
          <li>You are building a prototype for your startup?</li>
          <li>You are looking into building a custom tool for your buisiness?</li>
          <li>Your staff needs some specific training?</li>
          <li>Are you looking for an external point of view on a technology stack?</li>
        </ul>
        <p>
          As a fullstack independent developer I can help you reach your goals. Send me an{' '}
          <a href="mailto:steve@quaro.tech">email</a>!
        </p>
      </IllustratedText>
    </Section>
    <Section>
      <SectionTitle>Skills</SectionTitle>
      <Cards>
        <Card title="Fullstack" Illustration={Fullstack}>
          <p>A ease with fontend, backend as well as operations.</p>
        </Card>

        <Card title="Pragmatic and agile" Illustration={Balance}>
          <p>Looking for the right solution to a specific problem.</p>
        </Card>

        <Card title="Complexity as a challenge" Illustration={Puzzle}>
          <p>Able to grasp quickly a complex problem.</p>
        </Card>

        <Card title="Web plaform" Illustration={Web}>
          <p>A command of the platform beyond the tools of the day.</p>
        </Card>

        <Card title="Python specialist" Illustration={Python}>
          <p>More than 10 experience with the Python language and ecosystem.</p>
        </Card>

        <Card title="React.js specialist" Illustration={ReactLogo}>
          <p>A deep knowledge of the React.js framework</p>
        </Card>
      </Cards>
    </Section>
    <Section>
      <SectionTitle>Realisations</SectionTitle>
      <IllustratedText Illustration={LogoReplicad} small>
        <a href="https://replicad.xyz">Replicad</a> is a library to create computer assisted design
        (CAD) in the browser.
      </IllustratedText>
      <IllustratedText Illustration={LogoHoraire} imageLeft small>
        <a href="https://horair.es/en">Horair.es</a> is a web app to create and manage complex
        schedules.
      </IllustratedText>
      <IllustratedText Illustration={LogoPosologies} small>
        <a href="https://posologies.com">Posologies.com</a> allows medical professionals to access
        drug indications.
      </IllustratedText>
    </Section>
    <Section>
      <InfoBar>
        <EndLink href="mailto:steve@quaro.tech">
          <Email size="1em" />
          <span>Contact</span>
        </EndLink>
      </InfoBar>
    </Section>
  </Layout>
);

export default IndexPage;
